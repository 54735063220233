/* common */
.opa:hover {
  transition: 0.3s;
  opacity: 0.8;
}
/* title */
.c_title01 {
  color: #3fa9f5;
  font-size: 27px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
}
@media screen and (max-width: 650px) {
  .c_title01 {
    font-size: 22px;
    margin-bottom: 30px;
  }
}

.c_title02 {
  color: var(--color-primary-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 27px;
  margin-bottom: 40px;
  vertical-align: top;
}
.c_title02 img {
  margin-right: 15px;
  vertical-align: middle;
}

@media screen and (max-width: 650px) {
  .c_title02 {
    flex-direction: column;
    align-items: center;
    font-size: 22px;
    margin-bottom: 30px;
  }
  .c_title02 img {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
/* text */
.c_subtitle {
  text-align: center;
  color: #6fbff8;
  font-size: 16px;
  margin-bottom: 10px;
}
@media screen and (max-width: 650px) {
  .c_subtitle {
    font-size: 14px;
  }
}
/* button */
.c_button01 {
  background: #ff4f5b;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 370px;
  min-height: 70px;
  margin: 60px auto 0;
  padding: 15px 70px;
  border-radius: 35px;
  color: white;
  font-size: 25px;
  font-weight: bold;
  position: relative;
  cursor: pointer;
}
.c_button01:after {
  content: url(../images/page_recruit_form1_arrow.png);
  height: 100%;
  position: absolute;
  top: 3px;
  right: 25px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 650px) {
  .c_button01 {
    font-size: 18px;
    width: 100%;
    min-height: 60px;
    margin: 30px auto 0;
    padding: 15px 50px;
  }
  .c_button01:after {
    transform: scale(0.6);
    top: 2px;
    right: 15px;
  }
}
/* form_table */
.form_width {
  max-width: 670px;
  margin: auto;
}
.form_width table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}
.form_width table th,
.form_width table td {
  padding-bottom: 24px;
}
.form_width table th {
  padding-top: 2px;
}
.form_width table th {
  text-align: left;
  width: 170px;
  font-size: 20px;
  color: #000;
}
@media screen and (max-width: 650px) {
  .form_width table,
  .form_width table tbody,
  .form_width table tr,
  .form_width table th,
  .form_width table td {
    width: 100%;
    display: block;
  }
  .form_width table th {
    font-size: 18px;
    padding-bottom: 10px;
  }
  .form_width table td {
    padding-bottom: 0;
    margin-bottom: 20px;
  }
}
/* form */
input[type='password'],
input[type='text'],
textarea,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
/* IE11 */
select::-ms-expand {
  display: none;
}
input[type='password'],
input[type='text'] {
  border: none;
  background: #f4f4f4;
  width: 100%;
  font-size: 18px;
  padding: 10px;
}

input[type='password']:read-only,
input[type='text']:read-only {
  color: #495057;
  background: #e9ecef;
}

textarea {
  border: none;
  background: #f4f4f4;
  width: 100%;
  font-size: 18px;
  padding: 10px;
  resize: vertical;
}

button {
  border: none;
}
/* main_v */
.main_v .mywidth {
  display: flex;
  align-items: center;
  min-height: 180px;
  position: relative;
}
/* IE11 */
_:-ms-lang(x)::-ms-backdrop,
.main_v .mywidth {
  height: 180px;
}
.main_v .mywidth h1 {
  background: #3fa9f5;
  color: white;
  font-size: 25px;
  font-weight: bold;
  padding: 10px 20px;
}
.main_v {
  background: url(../images/topB_top.png) no-repeat center / cover;
}
.main_v.job {
  background: url(../images/topB_top.png) no-repeat center / cover;
}
.main_v.bus {
  background: url(../images/topB_business_top.png) no-repeat center / cover;
}

@media screen and (max-width: 650px) {
  .main_v .mywidth {
    padding: 0;
    min-height: 120px;
    margin-top:55px;    
  }
  .main_v .mywidth h1 {
    font-size: 12px;
    padding: 5px 15px;
  }
    #topB_business .main_v {
    background: url(../images/topB_business_top.png) no-repeat center /
      cover;
  }
}
/* topB.main_v */
#topB .main_v .mywidth {
  min-height: 360px;
}
#topB .main_v .mywidth h1 {
  width: 100%;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#topB .main_v .mywidth h1.sp {
  display: none;
}
#topB .main_v .mywidth span {
  background: #3fa9f5;
  color: white;
  font-size: 28px;
  font-weight: bold;
  padding: 5px 15px 8px;
  margin-bottom: 5px;
}
#topB .main_v .mywidth .box {
  position: absolute;
  right: 0;
  top: 25px;
}
#topB .main_v .mywidth .link {
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(84, 170, 237, 0.8);
  color: white;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 8px;
  padding: 10px;
}

@media screen and (max-width: 650px) {
  #topB .main_v .myw 　idth h1.pc {
    display: none;
    margin: auto;    
  }
  #topB .main_v .mywidth h1.sp {
    display: flex;
    text-align: center;
    padding-bottom: 40px;
    font-size:24px;
  }
  #topB .main_v .mywidth span {
    font-size: 15px;
    background: rgba(84, 170, 237, 0.8);
    padding:auto;
  }
  #topB .main_v .mywidth img {
    max-width: 100%;
    padding:auto;
  }
  #topB .main_v .mywidth .box {
    right: 0;
    left: 0;
    top: auto;
    bottom: 0;
    display: flex;
    padding:10px;
    margin-top:auto;
    margin-bottom:auto;
  }
  #topB .main_v .mywidth .link {
    width: 50%;
    background: rgba(84, 170, 237, 1);
    font-size: 12px;
    margin-bottom: 0;
  }
  #topB .main_v .mywidth .link:first-child {
    border-right: 1px solid #fff;
  }
}
/* topB_business.main_v */
#topB_business .main_v .mywidth {
  min-height: 360px;
}
#topB_business .main_v .mywidth h1 {
  width: 100%;
  background: none;
  display: flex;
  align-items: center;
}
#topB_business .main_v .mywidth .sp {
  display: none;
}
#topB_business .main_v .mywidth span {
  background: var(--color-secondary);
  color: white;
  font-size: 28px;
  font-weight: bold;
  padding: 5px 15px 8px;
  margin-bottom: 5px;
}
#topB_business .main_v .mywidth .box {
  position: absolute;
  right: 0;
  top: 25px;
}
#topB_business .main_v .mywidth .link {
  width: 170px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(216, 129, 51, 0.8);
  color: white;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 8px;
}
@media screen and (max-width: 650px) {
  #topB_business .main_v .mywidth {
    min-height: 300px;
  }
  #topB_business .main_v .mywidth h1 {
    justify-content: center;
  }
  #topB_business .main_v .mywidth span {
    font-size: 18px;
    background: rgba(216, 129, 51, 0.8);
  }
  #topB_business .main_v .mywidth img {
    max-width: 200px;
  }
  #topB_business .main_v .mywidth .box {
    right: 0;
    left: 0;
    top: auto;
    bottom: 0;
    display: flex;
  }
  #topB_business .main_v .mywidth .link {
    width: 50%;
    background: rgba(216, 129, 51, 1);
    font-size: 12px;
    margin-bottom: 0;
  }
  #topB_business .main_v .mywidth .link:first-child {
    border-right: 1px solid #fff;
  }
}


/* pankuzu */
.pankuzu {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.pankuzu li:last-child {
  position: relative;
  top: 1px;
}
.pankuzu li,
.pankuzu li a {
  font-size: 13px;
}
.pankuzu li:after {
  content: '/';
  font-size: 15px;
  margin: 0 5px;
}
.pankuzu li:last-child::after {
  display: none;
}
.pankuzu li a {
  color: #00429b;
}
.pankuzu li a:hover {
  transition: 0.3s;
  opacity: 0.6;
}
@media screen and (max-width: 650px) {
  .pankuzu {
    margin-top: 10px;
  }
  .pankuzu li,
  .pankuzu li a {
    font-size: 10px;
  }
}
/* c_box01 */
.c_box01 {
  display: flex;
  flex-wrap: wrap;
}
.c_box01 .box {
  min-width: 320px;
  min-height: 200px;
  margin-right: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: #3fa9f5;
}
.c_box01 .box:nth-child(3n) {
  margin-right: 0;
}
.c_box01 .box .image_wrap {
  height: 100px;
  display: flex;
  align-items: center;
}
.c_box01 .box .text {
  color: white;
  text-align: center;
  margin: 10px 0 25px;
  line-height: 1.8em;
}
.c_box01 .box .text span {
  color: white;
  display: block;
}
@media screen and (max-width: 650px) {
  .c_box01 {
    justify-content: space-between;
  }
  .c_box01 .box {
    min-width: 48%;
    margin-right: 0;
    margin-bottom: 4%;
  }
}
/* c_box02 */
.c_box02 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}
.c_box02 .box {
  width: 210px;
  min-width: 210px;
  position: relative;
}
.c_box02 .box:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0 12px 17px;
  border-color: transparent transparent transparent #3fa9f5;
  display: inline-block;
  position: absolute;
  right: -33px;
  top: 87px;
}
.c_box02 .box:last-child::after {
  display: none;
}
.c_box02 .box .image_wrap {
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c_box02 .box .image_wrap img {
  max-height: 100%;
  display: block;
}
.c_box02 .box .text_wrap .title {
  color:black;
  font-size: 19px;
  font-weight: bold;
  text-align: center;
  margin-top: 5px;
}
.c_box02 .box .text_wrap .title span {
  display: block;
  background: #3fa9f5;
  color: white;
  font-size: 19px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  border-radius: 30px;
}
.c_box02 .box .text_wrap .text {
  font-size: 15px;
  line-height: 1.6em;
  margin-top: 10px;
}
.c_box02>p {
  font-size: 15px;
}
.c_box02>.business_reason {
  padding: 0px 190px;
  text-align: center;
  font-size: 16px;
}

.c_box02>ol {
  counter-reset: list;
}
.c_box02>ol > li {
  list-style: none;
  position: relative;
  font-size: 15px;
}
.c_box02>ol > li:before {
  counter-increment: list;
  content: counter(list, decimal) ") ";
}
@media screen and (max-width: 650px) {
  .c_box02 {
    flex-direction: column;
  }
  .c_box02 .box {
    width: 100%;
    margin-bottom: 50px;
  }
  .c_box02 .box:after {
    right: 0;
    left: 0;
    top: auto;
    bottom: -40px;
    margin: auto;
    transform: rotate(90deg);
  }
  .c_box02 .box .text_wrap .title span {
    font-size: 16px;
    height: 30px;
    margin-top: 10px;
  }
  .c_box02 .box .text_wrap .text {
    font-size: 12px;
    padding: 0 10px;
  }

  .c_box02>.business_reason {
    font-size: 14px;
    padding: 0px 0px;
    text-align: center;
  }
}
/* c_box03 */
.c_box03 {
  background: #f0f7fd;
  margin-top: 100px;
}
.c_box03 .mywidth {
  min-height: 240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.c_box03 .mywidth .four_box {
  display: flex;
  width: 715px;
  position: relative;
  top: -45px;
  right: 0;
}
.c_box03 .mywidth .four_box .box {
  width: 170px;
  margin-left: 10px;
}
.c_box03 .mywidth .four_box .text {
  background: #3fa9f5;
  color: white;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.6em;
  min-height: 105px;
  padding: 20px 8px 10px;
}
.c_box03 .mywidth .four_box img {
  width: 100%;
  height: 127px;
  object-fit: cover;
}
@media screen and (max-width: 650px) {
  .c_box03 {
    padding: 60px 0;
  }
  .c_box03 h3 {
    max-width: 150px;
  }
  .c_box03 .mywidth {
    flex-direction: column;
  }
  .c_box03 .mywidth .four_box {
    flex-direction: column;
    align-items: center;
    width: 100%;
    top: auto;
    margin-top: 30px;
  }
  .c_box03 .mywidth .four_box .box {
    width: 100%;
    max-width: 300px;
    margin-left: 0;
    margin-bottom: 15px;
  }
  .c_box03 .mywidth .four_box .text {
    font-size: 15px;
    min-height: 0;
    padding: 20px 8px 20px;
    text-align: center;
  }
}
/* c_box04 */
.c_box04 {
  margin-top: 40px;
}
.c_box04 .inner_wrap {
  max-width: 780px;
  margin: auto;
}
.c_box04 .image_wrap {
  text-align: center;
}
.c_box04 .text {
  font-size: 15px;
  font-weight: bold;
  margin-top: 45px;
  line-height: 1.6em;
}
.c_box04 dl {
  display: flex;
  margin-top: 40px;
  font-weight: bold;
}
.c_box04 dl dt {
  color: #3fa9f5;
  width: 370px;
  min-width: 370px;
  font-size: 19px;
  line-height: 1.6em;
}
.c_box04 dl dd {
  font-size: 15px;
  line-height: 1.6em;
}

.c_box04 .feature {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 0.8fr;
  grid-template-rows: 1fr 1fr 1fr min-content;
  justify-items: center;
  column-gap: 10px;
  row-gap: 5px;
}

.c_box04 .feature > :nth-child(1) {
  grid-row: 1 / 4;
  align-self: center;
}

.c_box04 .feature > :nth-child(2) {
  justify-self: stretch;
  align-self: end;
}

.c_box04 .feature > :nth-child(2) > div {
  display: block;
  text-align: center;
}

.c_box04 .feature > :nth-child(2) > svg {
  display: block;
  stroke: var(--color-primary);
  fill: var(--color-primary);
}

.c_box04 .feature > :nth-child(3) {
  place-self: center;
  padding: 5px 20px;
  border: dotted var(--color-primary-dark) 2px;
  font-size: 24px;
  color: var(--color-primary-dark);
  font-weight: bold;
  white-space: nowrap;
}

.c_box04 .feature > :nth-child(4) {
  justify-self: stretch;
  align-self: start;
}

.c_box04 .feature > :nth-child(4) > div {
  display: block;
  text-align: center;
}

.c_box04 .feature > :nth-child(4) > svg {
  display: block;
  stroke: var(--color-secondary-dark);
  fill: var(--color-secondary-dark);
  transform: scale(-1,1);
}

.c_box04 .feature > :nth-child(5) {
  grid-row: 1 / 4;
  grid-column: 3 / 4;
  align-self: center;
}

.c_box04 .feature > :nth-child(6), 
.c_box04 .feature > :nth-child(8) {
  align-self: start;
  padding: 2px 5px;
  background-color: var(--color-primary-light);
  color: black;
  text-align: center;
}


@media screen and (max-width: 650px) {
  .c_box04 .image_wrap {
    overflow: auto;
    margin-right: -10px;
  }
  .c_box04 .image_wrap img {
    display: inline-block;
    min-width: 600px;
  }
  .c_box04 .text {
    font-size: 12px;
  }
  .c_box04 dl {
    flex-direction: column;
    margin-top: 25px;
  }
  .c_box04 dl dt {
    font-size: 14px;
    width: 100%;
    min-width: 0;
    margin-bottom: 10px;
    text-align: center;
  }
  .c_box04 dl dd {
    font-size: 12px;
  }

  .c_box04 .feature {
    margin-top: 25px;
  }

  .c_box04 .feature div {
    font-size: 12px;
  }

  .c_box04 .feature > :nth-child(3) {
    font-size: 16px;
  }
}
/* c_box05 */
.c_box05 {
  display: flex;
  justify-content: space-between;
}
.c_box05 .box {
  width: 20%;
  min-width: 20%;
  position: relative;
}
.c_box05 .box .image_wrap {
  min-height: 185px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
/* IE11 */
_:-ms-lang(x)::-ms-backdrop,
.c_box05 .box .image_wrap {
  height: 185px;
}

.c_box05 .box .text_wrap .title {
  color: #000;
  font-size: 19px;
  font-weight: bold;
  text-align: center;
  margin-top: 5px;
}
.c_box05 .box .text_wrap .title span {
  display: block;
  background: #3fa9f5;
  color: white;
  font-size: 19px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  border-radius: 30px;
}
.c_box05 .box .text_wrap .text {
  font-size: 16px;
  line-height: 1.6em;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 650px) {
  .c_box05 {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .c_box05 .box {
    width: 50%;
  }
  .c_box05 .box .text_wrap .title span {
    font-size: 16px;
    height: 30px;
    margin-top: 10px;
  }
  .c_box05 .box .text_wrap .text {
    font-size: 12px;
    padding: 0 10px;
  }
}
/* c_box06 */
.c_box06 table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}
.c_box06 table th {
  /* width: 124px; */
  width: 170px;
  text-align: left;
  vertical-align: top;
  overflow: hidden;
  padding-top: 15px;
}
.c_box06 table th .hissu {
  background: red;
  color: white;
  display: inline-block;
  float: right;
  font-size: 10px;
  padding: 1px 8px 3px;
  margin-top: 2px;
  border-radius: 3px;
}
.c_box06 table td {
  padding: 15px 15px 30px 20px;
}
.c_box06 table td ul li {
  display: inline-block;
  margin-right: 20px;
}
.c_box06 ul li input[type='checkbox'] {
  margin-right: 10px;
  position: relative;
}
.c_box06 ul li input[type='radio'] {
  margin-right: 10px;
  position: relative;
}
.c_box06 label {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.c_box06 .select_wrap {
  border: 3px solid #e3e3e3;
  width: 205px;
  max-width: 205px;
  position: relative;
}
.c_box06 .select_wrap:after {
  content: '\f0d7';
  font-family: 'FontAwesome';
  position: absolute;
  right: 10px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
}
.c_box06 .select_wrap select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: 100%;
  padding: 5px 25px 5px 10px;
}
.c_box06 table td textarea {
  border: 3px solid #e3e3e3;
  width: 100%;
  min-height: 150px;
  padding: 5px 25px 5px 10px;
}
.c_box06 input {
    border: 3px solid #ddd !important;
    padding: 15px 10px !important;
    font-size: 14px !important;
    background-color: #fff !important;
}
@media screen and (max-width: 650px) {
  .c_box06 table,
  .c_box06 tbody,
  .c_box06 tr,
  .c_box06 th,
  .c_box06 td {
    display: block;
    width: 100% !important;
  }
  .c_box06 table th .hissu {
    float: none;
    position: relative;
    top: -2px;
    right: -10px;
  }
  .c_box06 label {
    margin-bottom: 15px;
  }
}

.c_box07 {
  padding: 0px 30px;
  display: flex;
  justify-content:center;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 60px;
}

.c_box08 {
  background: #f0f7fd;
  margin-top: 100px;
  padding: 40px 0px;
}
.c_box08 .mywidth {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  place-items: center;
  gap: 20px;
  counter-reset: merit-counter;
}
.c_box08 .mywidth > img:first-child {
  grid-row-start: 1;
  grid-row-end: 3;
}

.c_box08 .mywidth > div {
  border: solid 3px var(--color-primary-dark);
  border-radius: 10px;
  width: 300px;
  height: 80px;
  padding: 10px 20px;
  background-color: white;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.c_box08 .mywidth > div::before {
  counter-increment: merit-counter;
  content: counter(merit-counter);
  color: var(--color-primary-dark);
  font-size: 34px;
  font-weight: bold;
  border-right: solid 3px;
  padding-right: 20px;
  margin-right: 20px;
}

@media screen and (max-width: 650px) {
  .c_box08 .mywidth {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

.c_title09 {
  color: var(--color-primary-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 27px;
  margin-bottom: 40px;
  vertical-align: top;
}
.c_title09 img {
  vertical-align: middle;
  height: 64px;
}

.c_box09 {
  margin: 0px;
  margin-bottom: 60px;
  padding: 30px 20px;
  background: var(--color-primary);
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.c_box09 > :first-child {
  margin: 0px;
}

.c_box09 > :first-child {
  display: grid;
  column-gap: 40px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: min-content min-content;
}

.c_box09 > :first-child > dt {
  grid-row: 1 / 2;
  font-weight: bold;
  color: var(--color-primary-dark);
  font-size: 16px;
}

.c_box09 > :first-child > dd {
  grid-row: 2 / 3;
  display: flex;
  align-items: center;
  justify-content:center;
  background: white;
  padding: 20px 50px;
  text-align: center;
  font-size: 16px;
  word-break: normal;

}

.c_box09 > :last-child > * {
  text-align: center;
  text-decoration: underline;
}

@media screen and (max-width: 650px) {

  .c_box09 > :first-child {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6,min-content);
  }

  .c_box09 > :first-child > dt {
    grid-row: unset;
  }
  
  .c_box09 > :first-child > dd {
    grid-row: unset;
  }
}

/* c_box_base */
.c_box_base .tag_wrap {
  display: table;
  width: 100%;
  table-layout: fixed;
  margin-bottom: 50px;
}
.c_box_base .tag_wrap .tag {
  background: #3fa9f5;
  color: white;
  width: 16%;
  height: 50px;
  display: table-cell;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #fff;
  text-align: center;
  vertical-align: middle;
  position: relative;
  padding: 10px;
}
.c_box_base .tag_wrap .tag:before {
  content: '';
  display: block;
  width: 80px;
  height: 30px;
  margin: 0 auto 10px;
}
.c_box_base .tag_wrap .tag:nth-child(1):before {
  /* background: url(../images/page_recruit_form1_1.png) no-repeat center / contain; */
  content: "1";
  margin: auto;
  font-size: 30px;
}
.c_box_base .tag_wrap .tag:nth-child(2):before {
  /* background: url(../images/page_recruit_form1_2.png) no-repeat center / contain; */
  content: "2";
  margin: auto;
  font-size: 30px;
}
.c_box_base .tag_wrap .tag:nth-child(3):before {
  /* background: url(../images/page_recruit_form1_3.png) no-repeat center / contain; */
  content: "3";
  margin: auto;
  font-size: 30px;
}
.c_box_base .tag_wrap .tag:nth-child(4):before {
  /* background: url(../images/page_recruit_form1_4.png) no-repeat center / contain; */
  content: "4";
  margin: auto;
  font-size: 30px;
}
.c_box_base .tag_wrap .tag:nth-child(5):before {
  /* background: url(../images/page_recruit_form1_5.png) no-repeat center / contain; */
  content: "5";
  margin: auto;
  font-size: 30px;
}
.c_box_base .tag_wrap .tag:nth-child(6):before {
  /* background: url(../images/page_recruit_form1_6.png) no-repeat center / contain; */
  content: "6";
  margin: auto;
  font-size: 30px;
}
.c_box_base .tag_wrap .tag.active {
  background: #2e3192;
}
.c_box_base .tag_wrap .tag.active:after {
  content: '';
  background-color: #2e3192;
  width: 100%;
  height: 5px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -8px;
}
@media screen and (max-width: 650px) {
  .c_box_base .tag_wrap {
    display: flex;
    flex-wrap: wrap;
  }
  .c_box_base .tag_wrap .tag {
    width: 50%;
    height: auto;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }
  .c_box_base .tag_wrap .tag:before {
    height: 30px;
    margin-bottom: 5px;
  }
  .c_box_base .tag_wrap .tag.active:after {
    display: none;
  }
}
.c_box_base .form_main_title {
  font-size: 20px;
  margin-bottom: 20px;
}
.c_box_base .form_title01 {
  font-size: 16px;
  padding-bottom: 10px;
}
.c_box_base .form_title02 {
  font-size: 16px;
  padding: 20px 0 10px;
}
.c_box_base .hissu {
  background: red;
  color: white;
  display: inline-block;
  float: right;
  font-size: 10px;
  padding: 1px 8px 3px;
  margin-top: 2px;
  border-radius: 3px;
}
.c_box_base .input01 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.c_box_base table th {
  text-align: left;
}
.c_box_base .form01,
.c_box_base .form02 {
  margin-bottom: 30px;
}
.c_box_base .input01 input {
  width: 49%;
}
.c_box_base .input02 {
  display: flex;
	
/*	210707 追加*/
	flex-wrap: wrap;
}
/*	210707 追加*/
@media screen and (max-width: 650px) {
	.c_box_base .input02 {
		display: inherit;
	}
}

.c_box_base .input02 label {
  margin-right: 20px;
  font-size: 18px;
}
.c_box_base .input02 label input {
  margin-right: 10px;
}
.c_box_base table select {
  width: 100%;
}
.c_box_base .select_wrap {
  width: 100%;
  position: relative;
  border: none;
}
.c_box_base .input03 {
  display: flex;
  justify-content: space-between;
}
.c_box_base .input03 .select_wrap {
  max-width: 33%;
}
.c_box_base .input04 {
  display: flex;
  justify-content: space-between;
}
.c_box_base .input04 .select_wrap {
  max-width: 49%;
}
.c_box_base .select_wrap:after {
  content: '\f0d7';
  font-family: 'FontAwesome';
  position: absolute;
  right: 10px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
}
.c_box_base .select_wrap select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: 100%;
  padding: 10px 25px 10px 10px;
  background: #f4f4f4;
  width: 100%;
  font-size: 18px;
}
.c_box_base .input05 input {
  margin: 20px 0 0;
}
@media screen and (max-width: 650px) {
  .c_box_base .form_main_title {
    font-size: 16px;
  }
  .c_box_base .form_title01 {
    font-size: 14px;
    padding-bottom: 10px;
  }
  .c_box_base .form02 {
    margin-bottom: 10px;
  }
  .c_box_base .form02 .input05:last-child {
    margin: 10px 0 0;
  }
  .c_box_base .input01 {
    flex-direction: column;
    width: 100%;
  }
  .c_box_base .input01 input {
    width: 100%;
    margin-bottom: 10px;
  }
  .c_box_base .input01 input[type='text'],
  .c_box_base .input05 input[type='text'] {
    font-size: 16px;
  }
  .c_box_base .input02 {
    flex-direction: column;
  }
  .c_box_base .input02 label {
    margin-bottom: 5px;
    font-size: 14px;
	  
/*	  210707 追加*/
	      display: inline-block;
  }
  .c_box_base .select_wrap {
    margin-bottom: 10px;
  }
  .c_box_base .select_wrap select {
    font-size: 16px;
  }
  .c_box_base .input04 {
    flex-direction: column;
  }
  .c_box_base .input04 .select_wrap {
    max-width: 100%;
  }
  .c_box_base .input04 .select_wrap select {
    margin-bottom: 0;
  }
  .c_box_base .input05 input {
    margin: 10px 0 0;
  }
  .c_box_base .input05 input:nth-child(1) {
    margin: 0 0 0;
  }
}
/* form_button next */
.form_button_wrap {
  display: flex;
  justify-content: space-between;
}
.form_button_wrap.first {
  justify-content: flex-end;
}
.prev,
.next {
  background: #ff4f5b;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 210px;
  min-height: 70px;
  margin: 60px 0 0;
  padding: 15px 50px;
  border-radius: 35px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  cursor: pointer;
  text-align: center;
}
.next:after {
  content: url(../images/page_recruit_form1_arrow.png);
  height: 100%;
  position: absolute;
  top: 3px;
  right: 20px;
  display: flex;
  align-items: center;
}
.prev:before {
  content: url(../images/page_recruit_form1_arrow.png);
  height: 100%;
  position: absolute;
  top: 3px;
  left: 20px;
  display: flex;
  align-items: center;
  transform: scale(-1, 1);
}
@media screen and (max-width: 650px) {
  .prev,
  .next {
    width: 140px;
    min-height: 40px;
    padding: 10px 30px;
    font-size: 14px;
  }
  .next:after {
    transform: scale(0.5);
    right: 10px;
  }
  .prev:before {
    transform: scale(0.5) rotate(180deg);
    left: 10px;
    top: 0;
  }
}
.c_box_base .input06 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.c_box_base .input06 .input_box {
  width: 49%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}
.c_box_base .input06 .input_box p {
  width: 100%;
  margin-bottom: 10px;
  font-size: 18px;
}
.c_box_base .input06 .input_box .select_wrap {
  width: 49%;
}
.c_box_base .input07 .select_wrap {
  margin: 20px 0;
}
.c_box_base .text_box01 textarea {
  border: none;
  background: #f4f4f4;
  width: 100%;
  font-size: 18px;
  padding: 10px;
  margin-bottom: 20px;
}
.c_box_base .input08 {
  margin-top: 10px;
}
.c_box_base .input08 p {
  font-size: 18px;
}
@media screen and (max-width: 650px) {
  .c_box_base .input06 {
    flex-direction: column;
    margin-bottom: 0;
  }
  .c_box_base .input06 .input_box {
    width: 100%;
  }
  .c_box_base .input06 .input_box p {
    font-size: 16px;
  }
  .c_box_base .input06 .input_box .select_wrap {
    width: 100%;
  }
  .c_box_base .input07 .select_wrap {
    margin: 10px 0 0;
  }
  .c_box_base .input07 .select_wrap:last-child {
    margin-bottom: 10px;
  }
  .c_box_base .input07 .select_wrap select {
    margin-bottom: 0;
  }
  .c_box_base .text_box01 textarea {
    max-height: 200px;
    font-size: 16px;
  }
}
.c_box_base .button_wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  table-layout: fixed;
  margin-bottom: 10px;
}
.c_box_base .button_wrap label {
  background: #3fa9f5;
  color: white;
  width: 32%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #fff;
  text-align: center;
  vertical-align: middle;
  position: relative;
  border-radius: 20px;
  margin-right: 2%;
  margin-bottom: 10px;
  cursor: pointer;
}
.c_box_base .button_wrap label:nth-child(3n) {
  margin-right: 0;
}
.c_box_base .button_wrap label input {
  display: none;
}
.c_box_base .text_box02 textarea {
  border: none;
  background: #f4f4f4;
  width: 100%;
  font-size: 18px;
  padding: 10px;
  margin-top: 20px;
}
.c_box_base .add_button01 {
  width: 120px;
  display: block;
  padding: 10px 20px 10px;
  margin-left: auto;
  cursor: pointer;
  background: #efeeef;
}
.c_box_base .add_button01.open_btn {
  background: #ff4f5b;
  color: white;
}
.c_box_base .add_button01.open_btn:before {
  content: '\f067';
  font-family: 'FontAwesome';
  margin-right: 10px;
}
.c_box_base .add_button01.delete_btn {
  background: #2e3192;
  color: white;
}
.c_box_base .add_button01.delete_btn:before {
  content: '\f068';
  font-family: 'FontAwesome';
  margin-right: 10px;
}
.c_box_base .add_button02 {
  width: 120px;
  display: block;
  padding: 10px 20px 10px;
  margin-left: auto;
  cursor: pointer;
  margin-top: 20px;
  background: #efeeef;
}
@media screen and (max-width: 650px) {
  .c_box_base .button_wrap {
    justify-content: space-between;
  }
  .c_box_base .button_wrap label {
    width: 49%;
    margin-right: 0;
  }
  .c_box_base .text_box02 textarea {
    max-height: 200px;
    font-size: 16px;
    margin-top: 10px;
  }
}
/* sns_wrap */
.sns_wrap {
  display: flex;
  align-items: center;
}
.sns_wrap .sns_button {
  flex: 1;
  /* width: 33.3333%; */
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  font-weight: bold;
  white-space: pre-wrap;
  text-align: center;
}
/* IE11 */
_:-ms-lang(x)::-ms-backdrop,
.sns_wrap .sns_button {
  height: 100px;
}
.sns_wrap .sns_button:nth-child(1) {
  background: var(--color-secondary-dark);
}
.sns_wrap .sns_button:nth-child(2) {
  background: #1877f2;
}
.sns_wrap .sns_button:nth-child(3) {
  background: #0a66c2;
}
@media screen and (max-width: 650px) {
  .sns_wrap {
    flex-direction: column;
    align-items: center;
    margin: 0px -10px;
  }
  .sns_wrap .sns_button {
    width: 100%;
    min-height: 70px;
    font-size: 18px;
  }
}
/* topB_pre */
#topB_pre main {
  background: url(../images/topB_pre_bg.jpg) no-repeat center / cover;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
/* IE11 */
_:-ms-lang(x)::-ms-backdrop,
#topB_pre main {
  height: 100vh;
}
#topB_pre .box {
  max-width: 1000px;
}
#topB_pre .box h1 {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: black;
  word-break: normal;
}
#topB_pre .box h1 img {
  margin-top: 10px;
}
#topB_pre .box h2 {
  margin-bottom: 20px;
}
#topB_pre .box h2 span {
  background-color: #38a8f8;
  color: white;
  font-size: 35px;
  padding: 8px 9px 7px;
  margin-bottom: 20px;
  display: inline-block;
  line-height: 1em;
}
#topB_pre .box h3 {
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding: 15px;
  position: relative;
}
#topB_pre .box h4 {
  font-size: 60px;
  font-weight: bold;
  text-align: center;
  color: white;
  margin-bottom: 35px;
}

#topB_pre .box p {
  font-size: 60px;
  font-weight: bold;
  text-align: left;
  color: white;
  margin-bottom: 12px; 
  margin-left: 12px; 
  line-height: 0.5;
}

#topB_pre .box .link_wrap {
  width: 780px;
  display: flex;
  margin: auto;
}
#topB_pre .box .link_wrap a {
  color: white;
  font-weight: bold;
  font-size: 28px;
  width: 50%;
  padding: 20px;
  position: relative;
  display: flex;;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

#topB_pre .box .link_wrap a > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
}

#topB_pre .box .link_wrap a > div > p {
  color: white;
  font-weight: bold;
  font-size: 24px;
  margin: 0px;
  line-height: 1.2;
}

#topB_pre .box .link_wrap a > img {

}

/*#topB_pre .box .link_wrap a:after {
  content: '';
  background: url(../images/topB_pre_4.png) no-repeat center / contain;
  display: inline-block;
  width: 30px;
  height: 15px;
  position: absolute;
  bottom: 15px;
  right: 10px;
}*/
#topB_pre .box .white_box {
  background-color: rgba(255, 255, 255, 0.9);
  /* background-image: url(../images/topB_pre_1.png);
  background-repeat: no-repeat;
  background-position:center top 60px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:10px;
  padding: 10px;
}

#topB_pre .box .link_wrap .blue_box {
  background-color: rgba(55, 158, 232, 0.9);
}

#topB_pre .box .link_wrap .orange_box {
  background-color: rgba(250, 154, 21, 0.9);
}
#topB_pre .copy {
  bottom: 20px;
  margin-top: 50px;
  font-size: 15px;
}

#topB_pre .box .information_container {
  background-color: rgba(255, 255, 255, 0.8);
  width: 780px;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-bottom: 50px;
  font-size: 20px;
  font-weight: bold;
  height: 100%;
}

@media screen and (max-width: 650px) {
  #topB_pre main {
    align-items: stretch;
    padding: 20px;
  }
  #topB_pre .box h1 { 
    font-size: 20px;
  }
  #topB_pre .box h1 img {
    margin-top: 10px;
    max-width: 280px;
  }
  #topB_pre .box h2 span {
    font-size: 21px;
    margin-bottom: 15px;
  }
  #topB_pre .box h3 {
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 15px;
  position: relative;
}
	#topB_pre .box h4 {
    font-size: 20px;
    margin-bottom: 35px;
  }
 

  #topB_pre .box .link_wrap {
    width: 100%;
    flex-direction: column;
  }
  #topB_pre .box .link_wrap a {
    width: 100%;
    font-size: 24px;
    background-position: top 15px right 15px !important;
    background-size: 100px;
  }
/*  #topB_pre .box .link_wrap a:after {
    bottom: 15px;
    right: auto;
    left: 20px;
  }*/
  #topB_pre .copy {
    font-size: 12px;
    text-align: center;
  }

  #topB_pre .box .information_container {
    width: 100%;
  }
}
/* page_recruit_form1 */
#page_recruit_form1 .form_text {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
}
@media screen and (max-width: 650px) {
  #page_recruit_form1 .form_text {
    font-size: 12px;
    text-align: left;
    margin-bottom: 30px;
    line-height: 1.8em;
  }
}
/* page_kiyaku */
#page_kiyaku .kiyaku_width {
  max-width: 800px;
  margin: auto;
}
#page_kiyaku .c_title02 {
  color: #00429b;
}
#page_kiyaku #main-wrapp .text,
#page_kiyaku .kiyaku dt,
#page_kiyaku .kiyaku dd {
  font-size: 15px;
  line-height: 1.8em;
  margin-bottom: 35px;
}
#page_kiyaku .kiyaku dt {
  font-weight: bold;
}


#page_kiyaku #main-wrapp .text,
#page_kiyaku .kiyaku_use dt,
#page_kiyaku .kiyaku_use dd {
  font-size: 15px;
  line-height: 1.8em;
  margin-bottom: 0px;
}
#page_kiyaku .kiyaku_use dt {
  font-weight: bold;
  margin-top: 35px;
}

@media screen and (max-width: 650px) {
  #page_kiyaku .kiyaku_width {
    margin: auto;
  }
}
/* page_corporate_form */
#page_corporate_form .c_title02 {
  color: #2e3192;
}
#page_corporate_form .c_button01.blue {
  background: #2e3192;
}
/* topB */
#topB h2 {
  margin-bottom: 20px;
}
/* topB_business */
#topB_business h2,
#topB_business .c_box02 .box .text_wrap .title {
  color: var(--color-secondary);
}
c #topB_business .c_box02 {
  margin-bottom: 100px;
}
#topB_business .c_box02 .box {
  width: 230px;
  min-width: 230px;
}
#topB_business .c_box02 .box:after {
  display: none;
}
#topB_business .c_box02 .box .text_wrap .title span {
  background: var(--color-secondary);
}
#topB_business .c_box04 .c_box02 .box {
  width: 160px;
  min-width: 160px;
}
@media screen and (max-width: 650px) {
  #topB_business .c_title02 {
    margin-bottom: 20px;
  }
  #topB_business .c_box02 {
    margin-bottom: 30px;
  }
  #topB_business .c_box02 .box {
    width: 100%;
  }
  #topB_business .c_box02 .box .image_wrap {
    height: auto;
    min-height: 0;
  }
}
/* ad_service */
.ad_service {
  display: flex;
  flex-direction: column;
  gap:20px;
  align-items: flex-start;
  max-width: 800px;
  margin: auto;
}

.ad_service h2 {
  align-self: center;
  width: 300px;
  margin-bottom: 10px;
}

.ad_service h3 {
  text-align: justify;
  font-size: 18px;
  color: var(--color-primary-dark);
  border-top: var(--color-primary-dark) dashed 2px;
  border-bottom: var(--color-primary-dark) dashed 2px;
}

.ad_service p {
  text-align: justify;
  font-size: 15px;
  margin-bottom: 10px;
}

.ad_service ol {
  counter-reset: list;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.ad_service ul {
  display: flex;
  flex-direction: column;
  gap: 5px;
}


.ad_service li {
  font-size: 15px;
}

.ad_service ol > li:before {
  display: inline-block;
  box-sizing: border-box;
  counter-increment: list;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgNTAiPjxwb2x5Z29uIHBvaW50cz0iMCwwIDgwLDAgMTAwLDI1IDgwLDUwIDAsNTAiIGZpbGw9IiMzMzMzOTkiIHN0cm9rZT0ibm9uZSIvPjwvc3ZnPg==");
  color: white;
  content: counter(list, decimal);
  font-size: 12px;
  width: 32px;
  padding-left: 10px;
  margin-right: 5px;
}

.ad_service ul>li>a {
  margin-left: 30px;
  float: right;
  color: var(--color-primary-dark);
  text-decoration: underline;
  font-weight: bold;
}

.ad_service div {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 5px;
  background-color: var(--color-primary);
  align-items: center;
  margin-top: 20px;
}

.ad_service div > a {
  margin: 0;
  background-color: white;
  border-radius: 3px;
  padding: 5px 30px;
}

.ad_service div > a > p {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.ad_service div > p {
  font-size: 12px;
  margin: 0;
}

/* mypage_conditional */
#mypage_conditional .c_button01.blue {
  background: #2e3192;
}
#mypage_conditional .c_button01:after {
  display: none;
}
/* form add, delete */
.all_form .clone_form.clone .delete_btn {
  display: block !important;
}
.all_form .clone_form,
.all_form .clone_form .input04,
.all_form .clone_form .input05 {
  margin-bottom: 20px;
}
.all_form .clone_form .input05 .no_margin {
  margin-top: 0;
}
@media screen and (max-width: 650px) {
  .all_form .clone_form .input05 .no_margin {
    margin-bottom: 10px;
  }
  .all_form .clone_form .input05 .sp_margin {
    margin-bottom: -10px;
  }
}



.login_part {
  width: 100%;
  min-height: 50vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.login_part p {
  text-align: center;
}
.main_layout {
  max-width: 1080px;
  width: 50%;
  margin: 0 auto;
}

.title {
  font-size: 32px;
  font-weight: bold;
  padding: 20px 0 40px 0;
}
.login_content ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.login_content ul .title {
  width: 30%;
  font-size: 18px;
  padding: 20px 0;
}
.login_content ul .input {
  width: 70%;
}
.tbinput {
  border: 1px solid #ddd !important;
  padding: 15px 10px !important;
  font-size: 14px !important;
  background-color: #fff !important;
}
.w100 {
  width: 100% !important;
}
.login_part p {
  text-align: center;
}
.login_btn {
  margin-top: 60px;
}
.login_forget {
  margin-top: 20px;
  font-weight: bold;
}
.btn {
  border-radius: 5px;
  background-color: #3fa9f5;
  padding: 15px 120px;
  border: none;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  box-shadow: rgb(31 139 216) 0px 5px 0px;
}
.forget_content {
  margin: 30px 0 20px 0;
}


.section h2.title {
  font-size: 150%;
  padding: 12px 0 12px 0;
  background-color: #3fa9f5;
  color: #fff;
  margin: 0 0 20px 0;
  border-radius: 5px;
}
.map_title {
  font-size: 20px;
  margin: 40px 0 10px 0;
  color: #3fa9f5;
  font-weight: bold;
}
.maps {
  margin-bottom: 30px;
}

.section p {
  line-height: 24px;
  text-align: center;
}

.section table {
  border-collapse: initial;
  margin: 15px 0px;
  background-color:#e0e5e8;
  border-spacing: 1px;
}
.section table tr th {
  width: 120px;
  background: #eef3fb;
  vertical-align: top;
  padding: 10px;
}
.section table tr td {
  background: #fafcfe;
  padding: 10px;
}


.editing > ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.editing_title {
  min-width: 260px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}
.editing_title .lbl_title {
  font-size: 16px;
}
.editing_input {
  width: 100%;
  padding-left: 20px;
}
.editing_input label {
  margin-left: 5px;
  margin-right: 10px;
}
.lbl_subtitle {
  font-size: 16px;
  width: 100px;
  display: inline-block;
  text-align: right;
}
.editing_input p {
  font-size: 12px;
  margin-bottom: 10px;
}
.txt_require {
  width: 42px;
  background-color: #f00;
  color: #fff;
  padding: 5px 10px;
  font-size: 11px;
}
.editing_input select {
  width: 35%;
  margin-right: 20px;
}
.editing_input input {
    width: auto;
}
.tinput {
  border: none;
  padding: 15px 10px;
  background: #f4f4f4;
  font-size: 14px;
}
.ul_top {
  align-items: start !important;
}

.header_sub_txt {
  margin-bottom: 20px;
  text-align: center;
  color: #7d7d7d;
}

.submit_btn_part {
  text-align: center;    
  margin-top: 50px;
  margin-bottom: 10px;
}

.header_sub_title {
    font-size: 20px;
    margin-bottom: 20px;
    position: relative;
    background-color: #2e3192;
    border-radius: 5px;
    padding: 10px 10px 10px 40px;
    color: #fff;
}
.header_sub_title::before {
    content: '';
    display: block;
    position: absolute;
    left: 15px;
    top: 50%;
    border-style: solid;
    border-width: 10px;
    border-color: transparent transparent transparent #fff6fb;
    transform: translate(0,-50%);
}
.scouted_content {
  width: 100%;
  margin: 0 auto;
}
.scouted_part {
    width: 100%;
    margin: 0 auto;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgb(176 176 176 / 50%) 0px 0px 5px 0px;
    margin-bottom: 20px;
}
.scouted_part ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
}
.scouted_part_content {
  width: 60%;
}
.scouted_part_content dl {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.scouted_part_content dt {
  width: 30%;
  line-height: 2em;
}
.scouted_part_content dd {
  width: 70%;
  line-height: 2em;
}
.scouted_part_detail_btn {
  width: 40%;
  text-align: right;
}

.detail_btn {
    background-color: #3a3a3add;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
}

.page_navi {
  margin: 80px 0;
  text-align: center;
}
.page_navi a {
  border: 1px solid #e8e8e8;
  background-color: #fff;
  padding: 5px 0px;
  margin-right: 10px;
  font-size: 12px;
  color: #2b2b2b;
  width: 35px;
  display: inline-block;
}
.page_navi .active {
  border: none;
  color: rgb(255, 110, 110);
  background: none;
}



.form_title_th {
    font-size: 16px;
    width: 33%;
    text-align: right !important;
    vertical-align: text-bottom;
}
.form_title_th2 {
  font-size: 16px;
  width: 20%;
  text-align: left !important;
  vertical-align: text-bottom;
}
.form_title_td {
    font-size: 18px;
    padding-left: 20px;
    vertical-align: text-bottom;
    color: #666;
}

.input01 label {
  width: 100%;
  font-size: 18px;
  color: #666;
  padding-bottom: 10px;
}

.mb10 {
	margin-bottom: 10px;
}


@media screen and (max-width: 650px) {
  .form_title_th2 {
    font-size: 16px;
    width: 25%;
    text-align: left !important;
    vertical-align: text-bottom;
  }
  .form_title_td {
    font-size: 14px;
    padding-left:0;
    vertical-align: text-bottom;
    color: #666;
  }
  .input01 label {
    width: 100%;
    font-size: 14px;
    color: #666;
    padding-bottom: 10px;
  }
  .submit_btn_part .btn {
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;

  }
  .editing ul {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
      flex-wrap: wrap;
  }
  .editing_title {
      min-width: auto;
      display: block;
      align-items: center;
      margin-bottom: 5px;
  }
  .lbl_title {
    width: auto;
    display: inline-block;
  }
  .editing_input {
    width: 100%;
    padding-left: 0px;
  }
  .txt_require {
    width: 40px;
    background-color: #f00;
    color: #fff;
    padding: 2px 4px;
    font-size: 10px;
  }
  .editing_input label {
    margin-right: 0px;
  }
  .lbl_subtitle {
    font-size: 16px;
    width: 100%;
    text-align: left;
  }
  .tinput {
    width: 100% !important;
  }
  .wp5s10 {
    width: 100% !important;
    margin: 0px !important;
  }


  .page_navi {
    margin: 40px 0;
    text-align: center;
  }
  .page_navi a {
    border: 1px solid #e8e8e8;
    background-color: #fff;
    padding: 5px 0px;
    margin-right: 3px;
    font-size: 10px;
    color: #2b2b2b;
    width: 30px;
    display: inline-block;
    margin-bottom: 10px;
  }

  .main_layout {
    max-width: 1080px;
    width: 90%;
    margin: 0 auto;
  }

  .login_content ul .title {
      width: 100%;
      font-size: 18px;
  }
  .login_content ul .input {
      width: 100%;
  }
  .login_btn {
    margin-top: 30px;
  }
  .login_content .btn{
    padding: 15px 0px;
    width: 100%;
  }

  .title {
      font-size: 24px;
  }

  .scouted_part_content {
    width: 100%;
  }
  .scouted_part_detail_btn {
    width: 100%;
    text-align: right;
    margin-top: 20px;
  }


}
.fixedBox{
  display: flex;
  flex-direction: column;
  z-index: 10;
  width: 250px;
  position: fixed;
  right: 0px;
  top: 70%;
}
.fixedBox .sns_button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  font-weight: bold;
  white-space:nowrap;
  height: 60px;
  text-overflow: ellipsis;
}

.fixedBox .sns_button > img{
  display: inline-block;
  height: 50px;
}

.fixedBox .sns_button:nth-child(1) {
  background: var(--color-linkedin);
}
.fixedBox .sns_button:nth-child(2) {
  background: var(--color-facebook);
}
.fixedBox .sns_button:last-child {
  background: var(--color-secondary-dark);
}

@media screen and (max-width: 650px) {
  .fixedBox{
    display: flex;
    flex-direction: row;
    z-index: 10;
    width: 100%;
    position: fixed;
    right: 0px;
    top: auto;
    margin-bottom:60px;  
    
  }

  .fixedBox .sns_button {
    flex:1;
    font-size: 12px;
    margin: auto; 
  }
}

.select_date {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
  justify-content: stretch;
}

.select_date > div {
  position: relative;
  flex: 1;
}

.select_date > div > select {
  margin: 0;
  padding: 0;
  color: #000;
  text-decoration: none;
  box-sizing: border-box;
  word-break: break-all;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: 100%;
  padding: 10px 25px 10px 10px;
  background: #f4f4f4;
  width: 100%;
  font-size: 18px;
}

.select_date > div::after {
  content: '\f0d7';
  font-family: 'FontAwesome';
  position: absolute;
  right: 10px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.ad_dashboard {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ad_dashboard .container {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  padding: 0px;

}

.ad_dashboard .container > div:first-child {
  padding: 0px;
  margin: 0px;
}

.ad_dashboard .container > div:first-child > div {
  padding: 10px 20px 10px 20px;
  margin: 0px;
  background: #3fa9f5;
  color: white;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  min-width: 200px;
}

.ad_dashboard .container > div:nth-child(2),
.ad_dashboard .container > div:nth-child(3),
.ad_dashboard .container > div:nth-child(4) {
  padding: 20px;
  margin: 0px;
}

.ad_dashboard .ads_table {
  border-collapse: collapse;
}

.ad_dashboard .ads_table th {
  border-bottom: 1px solid #333;
  padding: 10px 18px;
}

.ad_dashboard .ads_table td {
  border-bottom: 1px solid #ddd;
  padding: 10px 18px;
}

.ad_dashboard .ads_table button {
  color: white; 
  padding: 5px 10px;
  background-color: gray; 
  border-radius: 3px;
}

.ad_dashboard .ads_table button.red {
  background-color: #F43F3F;
  box-shadow: #be3131 0px 3px 0px; 
}

.ad_dashboard .ads_table button.green {
  background-color: #3FF451;
  box-shadow: #31c240 0px 3px 0px;
}

.ad_dashboard .ads_table button.blue {
  background-color: #3fa9f5;
  box-shadow: #3184be 0px 3px 0px; 
}

.ad_dashboard .ads_table input {
  width: 100%;
  min-width: 80px;
  border: initial;
  background: #f4f4f4;
  font-size: initial;
  padding: initial;
}

.ad_dashboard .container .next {
  all: initial;
}

.ad_dashboard .container .next:after {
  all: initial;
}

.ad_dashboard .container .previous {
  all: initial;
}

.ad_dashboard > a {
  align-self: center;
}

.manage_provider {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
}

.manage_provider label,
.manage_provider h1 {
  font-size: 16px;
}

.manage_provider button:hover {
  cursor: pointer;
}

.manage_provider > div:first-child > div {
  border: solid 1px lightgray;
  background-color: #fefef2;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.manage_provider > div:first-child > div:nth-child(1) {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 
    "cloud1 cloud2 cloud3";
  justify-items: center;
  padding: 40px;
  display: none;
}

.manage_provider > div:first-child > div:nth-child(2) {
  display: flex;
}

.manage_provider > div:first-child > div:nth-child(1) > div {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  font-size: 20px;
  padding: 40px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
}

.manage_provider > div:first-child > div:nth-child(1) > div:nth-child(1) {
  background-image: url(../images/cloud1.svg);
  width: 248px;
  height: 174px;
  grid-area: cloud1;
  animation: jump1 2.5s 0s infinite normal;
}

.manage_provider > div:first-child > div:nth-child(1) > div:nth-child(2) {
  background-image: url(../images/cloud2.svg);
  width: 234px;
  height: 172px;
  grid-area: cloud2;
  animation: jump2 2.5s 0s infinite normal;
  align-self:flex-end;
}

.manage_provider > div:first-child > div:nth-child(1) > div:nth-child(3) {
  background-image: url(../images/cloud3.svg);
  width: 252px;
  height: 170px;
  grid-area: cloud3;
  animation: jump3 2.5s 0s infinite normal;
}

.manage_provider > div:nth-child(n+2):nth-child(-n+3) {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
}

.manage_provider > div:nth-child(n+2):nth-child(-n+3) > :nth-child(2){
  display: grid;
  grid-template-columns: 1fr min-content 3fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
}

.manage_provider > div:nth-child(n+2):nth-child(-n+3) > :nth-child(2) > span {
  display: flex;
}

.manage_provider > div:nth-child(n+2):nth-child(-n+3) > :nth-child(2) > :nth-child(3n+2) {
  border: solid 2px red;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  place-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 18px;
  font-weight: bold;
  padding: 0px;
  line-height: 1;
}

.manage_provider > div:nth-child(n+2):nth-child(-n+3) > :nth-child(2) > :nth-child(3) {
  justify-self: left;
}

.manage_provider > div:nth-child(n+2):nth-child(-n+3) > :nth-child(2) > :nth-child(1) {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  align-self: center;
}

.manage_provider > div:nth-child(n+2):nth-child(-n+3) > :nth-child(2) > :nth-child(2) {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
}

.manage_provider > div:nth-child(n+2):nth-child(-n+3) > :nth-child(2) > :nth-child(3) {
  grid-row: 1 / 2;
  grid-column: 3 / 4;
}

.manage_provider > div:nth-child(n+2):nth-child(-n+3) > :nth-child(2) > :nth-child(4) {
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  align-self: center;
}

.manage_provider > div:nth-child(n+2):nth-child(-n+3) > :nth-child(2) > :nth-child(5) {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
}

.manage_provider > div:nth-child(n+2):nth-child(-n+3) > :nth-child(2) > :nth-child(6) {
  grid-row: 2 / 3;
  grid-column: 3 / 4;
}

.manage_provider .profile_url_container {
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: 1fr min-content;
}

.manage_provider .profile_url_container > input:focus {
  outline: none;
}

.manage_provider .profile_url_status {
  grid-column: 1 / 3;
  overflow: hidden;
  background-color: #d5eaff;
}

.manage_provider .profile_url_status > div {
  background-color: var(--color-primary);
  height: 3px;
}

.manage_provider .profile_url_status.success > div {
  background-color: green;
}

.manage_provider .profile_url_status.error > div {
  background-color: red;
}

.manage_provider .profile_url_status.progress > div {
  background-color: var(--color-primary);
  width: 100%;
  height: 3px;
  animation: indeterminateAnimation 1.5s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}

.manage_provider > div:nth-child(n+2):nth-child(-n+3) > :nth-child(2) > :nth-child(7) {
  grid-row: 2 / 3;
  grid-column: 1 / 4;
  border: solid 2px red;
  border-radius: 5px;
  margin: -10px;
  position: relative;
  pointer-events: none;
}

.manage_provider > div:nth-child(n+2):nth-child(-n+3) > :nth-child(2) > :nth-child(7) > div {
  position: absolute;
  right: 100px;
  top: -50px;
  color: red;
  word-break: break-word;
  width: 195px;
}

.manage_provider > div:nth-child(n+2):nth-child(-n+3) > :nth-child(2) > :nth-child(7) > div::before {
  height: 35px;
  border-left: solid 2px red;
  position: absolute;
  left: -20px;
  bottom: 0px;
  content: "";
  transform: rotate(-45deg);
}

.manage_provider > div:nth-child(n+2):nth-child(-n+3) > :nth-child(2) > :nth-child(7) > div::after {
  height: 35px;
  border-left: solid 2px red;
  position: absolute;
  right: -20px;
  bottom: 0px;
  content: "";
  transform: rotate(45deg);
}


.manage_provider .skip_button {
  align-self: stretch;
  background-color: var(--color-secondary-dark);
  color: white;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
  font-size: 16px;
}

.manage_provider .sns_button {
  color: white;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  gap:5px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
}

.manage_provider .sns_button > img {
  height: 50px;
}
.manage_provider .sns_button.linkedin {
  background-color: var(--color-linkedin);
}
.manage_provider .sns_button.facebook {
  background-color: var(--color-facebook);
}

/* .facebook_button::before {
  content: url(../images/facebook_logo.svg);
  width: 50px;
  height: 50px;
} */



@media screen and (max-width: 650px) {
  .manage_provider {

  }

  .manage_provider > div:first-child > div {
    height: 360px;
  }

  .manage_provider > div:first-child > div:nth-child(1) {
    grid-template-columns: minmax(0,1fr) minmax(0,1fr);
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 
      "cloud1 cloud1"
      "cloud2 cloud3";
    padding-left: 0px;
    padding-right: 0px;
  }

  .manage_provider > div:first-child > div:nth-child(2) > img {
    height: 100%;
  }

  .manage_provider > div:first-child > div:nth-child(1) > div {
    font-size: 15px;
    padding:20px;
  }
  

  .manage_provider > div:first-child > div:nth-child(1) > div:nth-child(1) {
    width: calc(248px * 2 / 3);
    height: calc(174px * 2 / 3);
    align-self:flex-end;
  }
  
  .manage_provider > div:first-child > div:nth-child(1) > div:nth-child(2) {
    width: calc(234px * 2 / 3);
    height: calc(172px * 2 / 3);
    align-self:flex-start;
  }
  
  .manage_provider > div:first-child > div:nth-child(1) > div:nth-child(3) {
    width: calc(252px * 2 / 3);
    height: calc(170px * 2 / 3);
    align-self:flex-start;
  }

  .manage_provider > div:nth-child(n+2):nth-child(-n+2) > :nth-child(2){
    row-gap: 45px;
  }

  .manage_provider > div:nth-child(n+2):nth-child(-n+3) > :nth-child(2) > :nth-child(7) > div {
    position: absolute;
    left: 10%;
    top: -25px;
    right: unset;
    color: red;
    word-break: break-word;
    width: unset;
    white-space: nowrap;
    font-size: 12px;
  }
  
  .manage_provider > div:nth-child(n+2):nth-child(-n+3) > :nth-child(2) > :nth-child(7) > div::before {
    height: 25px;
    border-left: solid 2px red;
    position: absolute;
    left: -20px;
    bottom: 0px;
    content: "";
    transform: rotate(-45deg);
  }
  
  .manage_provider > div:nth-child(n+2):nth-child(-n+3) > :nth-child(2) > :nth-child(7) > div::after {
    height: 25px;
    border-left: solid 2px red;
    position: absolute;
    right: -20px;
    bottom: 0px;
    content: "";
    transform: rotate(45deg);
  }
}

.list_table {
  border-collapse: collapse;
}

.list_table th {
  border-bottom: 1px solid #333;
  padding: 10px;
}

.list_table td {
  border-bottom: 1px solid #ddd;
  padding: 10px;
}

.list_table button {
  color: white;
  padding: 5px 10px;
  background-color: gray;
  border-radius: 3px;
  white-space: nowrap;
}

.list_table button.red {
  background-color: #F43F3F;
  box-shadow: #be3131 0px 3px 0px;
}

.list_table button.green {
  background-color: #3FF451;
  box-shadow: #31c240 0px 3px 0px;
}

.list_table button.blue {
  background-color: #3fa9f5;
  box-shadow: #3184be 0px 3px 0px;
}

.language_container_main {
  display: flex;
  flex-direction: column;
}

.language_container_main ul {
  width: 800px;
  display: grid;
  grid: auto-flow 60px / repeat(3, 200px);
  column-gap: 40px;
  row-gap: 20px;
  justify-content: center;
  align-content: center;
}

.language_container_main li {

}

.language_container_main button {
  width: 100%;
  height: 100%;
  color: white;
  font-weight: bold;
  font-size: 16px;
  background-color: #3fa9f5;
  border-radius: 5px;
  box-shadow: #3184be 0px 5px 0px;
}

.c_box_base .input_flex {
  display: flex;
  row-gap: 10px;
  column-gap: 20px;
  margin-bottom: 20px;
  flex-direction: row;
  flex-wrap: wrap;
}

.c_box_base .input_flex p,
.c_box_base .input_flex input[type='text'] {
  font-size: 18px;
}

.c_box_base .input_flex>* {
  margin: 0px;
}

.c_box_base .input_flex>.d4 {
  flex: 4 1 80%;
}

.c_box_base .input_flex>.d3 {
  flex: 3 1 60%;
}

.c_box_base .input_flex>.d2 {
  flex: 2 1 40%;
}

.c_box_base .input_flex>.d1 {
  flex: 1 1 20%;
}

.cr_btn {
  display: block;
  color: white;
  font-size: 18px;
  background-color: #3fa9f5;
  margin: 0px;
  padding: 10px;
  text-align: center;
  white-space: nowrap;
}

.cr_btn:disabled {
  color: white;
  background-color: gray;
}

.cr_btn.spinner::before {
  content: '\f110';
  font-family: 'FontAwesome';
  margin-right: 10px;
}

.cr_btn i {
  float: left;
  color: white;
  vertical-align: middle;
}

.sns_btn {
  color: white;
  font-size: 18px;
  background-color: #3fa9f5;
  margin: 0px;
  padding: 10px;
  text-align: center;
  width: 200px;
  margin-top: 10px;
  float: right;
}

.oauth_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.oauth_container>div {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.oauth_container .oauth_title {
  font-size: 18px;
}

.oauth_container .oauth_name {
  font-weight: bold;
  font-size: 16px;
}

.oauth_container .oauth_email {
  font-size: 16px;
  color: gray;
}

.oauth_container .oauth_password {
  border: 1px solid #ddd;
  padding: 15px 10px;
  font-size: 14px;
  background-color: #fff;
  width: 300px;
}

.oauth_container>div>* {
  align-self: center;
}

.oauth_container img {
  width: 64px;
  height: 64px;
  border-radius: 3px;
}

.oauth_container button {
  color: white;
  font-size: 18px;
  background-color: #3fa9f5;
  margin: 0px;
  padding: 10px 20px;
  text-align: center;
  width: 300px;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal_content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 10px;
  border: 1px solid #888;
  width: 600px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  font-size: 16px;
}

.modal_content_preview {
  display: block;
  background-color: #fefefe;
  
  padding: 10px;
  border: 1px solid #888;
  width: 100%;
  height: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  font-size: 16px;
  -ms-zoom: 0.8;
  -moz-transform: scale(0.8);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.8);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.8);
  -webkit-transform-origin: 0 0;
  margin: 5% 10%;
}

.stamp {
  position: relative;
  text-align: center;
}

.match {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: rgb(185, 11, 11);
}

.match span {
  font-size: 22px;
  color: rgb(185, 11, 11);
}

.match br {
  display: block;
  content: "";
  margin-bottom: -.5em;
}

.ad_mail {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  align-items: start;
}

.ad_mail > div:first-child {
  display: flex;
  flex-direction: column;
  gap: 20px; 
}

.ad_mail > div:first-child > div {
  padding: 10px;
  display: grid;
  column-gap: 10px;
  grid-template-columns: 2fr 1fr min-content;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
}

.ad_mail > div:first-child > div > div:nth-child(-n+5){
  grid-column: 1 / 2;
}

.ad_mail > div:first-child > div > div:nth-child(6){
  grid-column: 2 / 2;
  grid-row: 1 / 6;
  place-self: center;
}

.ad_mail > div:first-child > div > div:nth-child(7){
  grid-column: 3 / 4;
  grid-row: 1 / 6;
  place-self: end;
}

.ad_mail > div:last-child {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 2fr;
}

.ad_mail > div:last-child > div:nth-child(-n+4) {
  height: 160px;
}

.ad_mail > div:last-child > div:nth-child(-n+4) > form {
  display: contents;
}

.ad_mail > div:last-child > div:nth-child(-n+4) > form > a {
  display: flex;
  width:100%;
  height:100%;
  background: black;
  justify-content: center;
  align-items: center;
}

.ad_mail > div:last-child > div:nth-child(-n+4) > form > a > img {
  display: block;
  height: auto;
  width: auto;
  max-width:100%;
  max-height:100%;
}

.ad_mail > div:last-child > div:nth-child(5) {
  grid-column: 1 / 3;
}

.ad_mail > div:last-child > div:nth-child(5) > iframe {
  width: 100%;
  height: 100%;
}

.ad_mail > div:last-child > div > svg {
  width: 30px;
  height: 30px;
  stroke: lightslategray;
}

.ad_mail > :last-child > * {
  border: solid lightslategray 1px;
}

@media screen and (max-width: 650px) {
  .ad_mail {
      display: grid;
      gap: 10px;
      grid-template-columns: 1fr 1fr;
      align-items: start;
  }
  
  .ad_mail > div:first-child {
      display: flex;
      flex-direction: column;
      gap: 10px; 
  }
  
  .ad_mail > div:first-child > div {
      padding: 10px;
      display: grid;
      column-gap: 10px;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(6, min-content);
  }
  
  .ad_mail > div:first-child > div > div:nth-child(-n+5){
  
  }
  
  .ad_mail > div:first-child > div > div:nth-child(6){
      grid-column: 1 / 2;
      grid-row: 6 / 7;
      place-self: stretch;
  }
  
  .ad_mail > div:last-child {
      display: grid;
      gap: 10px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 2fr;
  }
  
  .ad_mail > div:last-child > div:nth-child(-n+4) {
      height: 80px;
  }
}

.mypage_scouted_conditional{
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  align-items: stretch;
  margin-bottom: 40px;
}

.mypage_scouted_conditional > :first-child{
  display: grid;
  gap: 10px;
  grid-template-columns: min-content 1fr;
  grid-template-rows: auto;
  align-items: start;
  padding: 10px;
  background: #cddef0;
}

.mypage_scouted_conditional > :first-child > :first-child{
  grid-column: 1 / 3;
}

.mypage_scouted_conditional > :first-child > :nth-child(2n){
  white-space: nowrap;
  font-weight: bold;
}

.mypage_scouted_conditional > :last-child{
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 2fr;
  place-items: stretch;
}

.mypage_scouted_conditional > :last-child > :nth-child(-n+4) {
  height: 160px;
}

.mypage_scouted_conditional > :last-child > :nth-child(-n+4) > form {
  display: contents;
}

.mypage_scouted_conditional > :last-child > :nth-child(-n+4) > form > a {
  display: flex;
  width:100%;
  height:100%;
  background: black;
  justify-content: center;
  align-items: center;
}

.mypage_scouted_conditional > :last-child > :nth-child(-n+4) > form > a > img {
  display: block;
  height: auto;
  width: auto;
  max-width:100%;
  max-height:100%;
}

.mypage_scouted_conditional > :last-child > :last-child{
  grid-column: 1 / 3;
}

.mypage_scouted_conditional > :last-child > :last-child > iframe{
  height: 100%;
  width: 100%;
}

.mypage_scouted_conditional > :last-child > div > svg {
  width: 30px;
  height: 30px;
  stroke: lightslategray;
}

.mypage_scouted_conditional > :last-child > * {
  border: solid lightslategray 1px;
}

@media screen and (max-width: 650px) {
  .mypage_scouted_conditional > :first-child{
      grid-template-columns: 1fr;
  }
  
  .mypage_scouted_conditional > :first-child > :first-child{
      grid-column: 1 / 2;
  }

  .mypage_scouted_conditional > :last-child > :nth-child(-n+4) {
      height: 80px;
  }
}

.ad_order {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-start;
}


.ad_order > * {
  padding: 10px;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  align-items: stretch;
  border: gray solid 1px;
}

.ad_order > * > :last-child {
  grid-column: 1 / 3;
}


@media screen and (max-width: 650px) {

  .c_box_base .input_flex p,
  .c_box_base .input_flex input[type='text'] {
      font-size: 16px;
  }

  .c_box_base .input_flex>.m4 {
      flex: 4 1 80%;
  }

  .c_box_base .input_flex>.m3 {
      flex: 3 1 60%;
  }

  .c_box_base .input_flex>.m2 {
      flex: 2 1 40%;
  }

  .c_box_base .input_flex>.m1 {
      flex: 1 1 20%;
  }

  .language_container_main ul {
    width: 100%;
    grid: auto-flow 60px / repeat(1, 200px);
    row-gap: 10px
  }
}

.spinner {
  color: #3fa9f5;
  font-size: 3px;
  margin: 15px auto;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load4 {

  0%,
  100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }

  12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }

  25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }

  37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }

  75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }

  87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

@keyframes load4 {

  0%,
  100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }

  12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }

  25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }

  37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }

  75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }

  87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.container_main {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 30px;

}

.container_main .accent_1 {
  background: linear-gradient(to bottom, #fff 80px, var(--color-primary-lightest) 80px);
}

.container_main .accent_2 {
  background: var(--color-primary-lightest)
}

.container_main > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0px;
}

.container_main > div > div {
  width: 1000px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.container_main > div > div > h2 {
  color: var(--color-primary-dark);
  font-size: var(--font-size-3xl);
  margin-bottom: 0px !important;
}


@media screen and (max-width: 650px) {
  .container_main > div > div {
    width: 100%;
    padding: 0px 10px;
  }
}

.container_main.jobseeker > div > div > h2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container_main.jobseeker > div > div > h2 > img {
  width: 200px;
}

.container_main.jobseeker .sub_1 > ul {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 5px;
  row-gap: 30px;
}

.container_main.jobseeker .sub_1 > ul > li > figure {
  background-color: var(--color-primary-lightest);
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  height: 100%;
}

.container_main.jobseeker .sub_1 > ul > li > figure > figcaption {
  font-size: var(--font-size-md);
  text-align: center;
}



.container_main.jobseeker .sub_2 > ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  place-items: center;
  column-gap: 40px;
  row-gap: 20px;
  counter-reset: merit-counter;
  align-self: center;
}

.container_main.jobseeker .sub_2 > ul > li {
  display: flex;
  gap: 20px;
  border: solid 3px var(--color-primary-dark);
  border-radius: 10px;
  width: 350px;
  height: 80px;
  padding: 10px 20px;
  background-color: white;
  font-size: var(--font-size-lg);
  align-items: center;
}

.container_main.jobseeker .sub_2 > ul > li::before {
  counter-increment: merit-counter;
  content: counter(merit-counter);
  color: var(--color-primary-dark);
  font-size: var(--font-size-4xl);
  font-weight: bold;
  border-right: solid 3px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  line-height: 1;
  height: 100%;
}

.container_main.jobseeker .sub_3 > p {
  text-align: center;
  padding: 0px 150px;
  font-size: var(--font-size-md);
}

.container_main.jobseeker .sub_3 > .feature {
  padding: 0px 110px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr min-content;
  justify-items: center;
  column-gap: 10px;
  row-gap: 5px;
}

.container_main.jobseeker .sub_3 > .feature > :nth-child(1) {
  grid-row: 1 / 4;
  align-self: center;
}

.container_main.jobseeker .sub_3 > .feature > :nth-child(2) {
  justify-self: stretch;
  align-self: end;
}

.container_main.jobseeker .sub_3 > .feature > :nth-child(2) > div {
  display: block;
  text-align: center;
}

.container_main.jobseeker .sub_3 > .feature > :nth-child(2) > svg {
  display: block;
  stroke: var(--color-primary);
  fill: var(--color-primary);
}

.container_main.jobseeker .sub_3 > .feature > :nth-child(3) {
  place-self: center;
  padding: 5px 20px;
  border: dotted var(--color-primary-dark) 2px;
  font-size: 24px;
  color: var(--color-primary-dark);
  font-weight: bold;
  white-space: nowrap;
}

.container_main.jobseeker .sub_3 > .feature > :nth-child(4) {
  justify-self: stretch;
  align-self: start;
}

.container_main.jobseeker .sub_3 > .feature > :nth-child(4) > div {
  display: block;
  text-align: center;
}

.container_main.jobseeker .sub_3 > .feature > :nth-child(4) > svg {
  display: block;
  stroke: var(--color-secondary-dark);
  fill: var(--color-secondary-dark);
  transform: scale(-1,1);
}

.container_main.jobseeker .sub_3 > .feature > :nth-child(5) {
  grid-row: 1 / 4;
  grid-column: 3 / 4;
  align-self: center;
}

.container_main.jobseeker .sub_3 > .feature > :nth-child(6), 
.container_main.jobseeker .sub_3 > .feature > :nth-child(8) {
  align-self: start;
  padding: 2px 5px;
  background-color: var(--color-primary-light);
  color: black;
  text-align: center;
}

.container_main.jobseeker .sub_3 > dl {
  padding: 0px 110px;
  display: flex;
  gap: 20px;
}

.container_main.jobseeker .sub_3 > dl > dt {
  background-color: var(--color-primary-lightest);
  color: var(--color-primary-dark);
  border-radius: 20px;
  padding: 20px;
  flex: 2;
  font-size: var(--font-size-lg);
  font-weight: bold;
  position: relative;
}

.container_main.jobseeker .sub_3 > dl > dt::after {
  background-color: var(--color-primary-lightest);
  /* fill: var(--color-primary-lightest); */
  position: absolute;
  content: '';
  -webkit-mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAxMCI+PHBvbHlnb24gcG9pbnRzPSIxMCAwLCAyMCAwLCAwIDEwIiAvPjwvc3ZnPg=="), no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAxMCI+PHBvbHlnb24gcG9pbnRzPSIxMCAwLCAyMCAwLCAwIDEwIiAvPjwvc3ZnPg=="), no-repeat 50% 50%;
  mask-size: cover;
  width: 60px;
  height: 30px;
  top: 100%;
  left: 0;
}

.container_main.jobseeker .sub_3 > dl > dt::before {
  position: absolute;
  right: 100%;
  top: 100%;
  content: url("/images/jobseeker_x_1.png");
  animation: shake 1s infinite linear;
  transform-origin: 80% 80%;
}

@keyframes shake {
  70% {
    transform: rotate(-20deg);
  }
}

.container_main.jobseeker .sub_3 > dl > dd {
  flex: 3;
  font-size: var(--font-size-md);
}

.container_main.jobseeker .sub_4 > details {
  background-color: var(--color-primary-lightest);
}

.container_main.jobseeker .sub_4 > details > summary {
  background-color: var(--color-primary-lightest);
  font-size: var(--font-size-lg);
  padding: 5px 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  line-height: 1;
  list-style: none;
  cursor: pointer;
}

.container_main.jobseeker .sub_4 > details > summary::before {
  content: "Q.";
  font-size: var(--font-size-2xl);
  font-weight: 900;
  font-family: 'Bai Jamjuree', sans-serif;
}

.container_main.jobseeker .sub_4 > details > div {
  background-color: var(--color-primary-lightest);
  font-size: var(--font-size-md);
  padding: 10px 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  list-style: none;
  border-top: solid 1px lightgray;
}

.container_main.jobseeker .sub_4 > details > div > p {
  font-size: var(--font-size-md);
}

.container_main.jobseeker .sub_4 > details > div::before {
  content: "A.";
  font-size: var(--font-size-2xl);
  font-weight: 900;
  font-family: 'Bai Jamjuree', sans-serif;
}

@media screen and (max-width: 650px) {
  .container_main.jobseeker .sub_2 > ul {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
    column-gap: 40px;
    row-gap: 20px;
  }
  
  .container_main > div > div {
    width: 100%;
  }

  .container_main.jobseeker .sub_1 > ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    column-gap: 5px;
    row-gap: 30px;
  }
  
  .container_main.jobseeker .sub_1 > ul > li > figure {
    background-color: var(--color-primary-lightest);
    padding: 10px 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    align-items: center;
    height: 100%;
    justify-content: stretch;
  }

  .container_main.jobseeker .sub_1 > ul > li:nth-child(2n) > figure {
    background-color: var(--color-primary-lightest);
    padding: 10px 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    align-items: center;
    height: 100%;
    justify-content: stretch;
  }

  .container_main.jobseeker .sub_1 > ul > li:nth-child(2n) > figure > img {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  } 
  
  .container_main.jobseeker .sub_1 > ul > li:nth-child(2n) > figure > figcaption {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }


  .container_main.jobseeker .sub_3 > p {
    padding: 0px 0px;
  }

  .container_main.jobseeker .sub_3 .feature {
    padding: 0px 0px;
  }

  .container_main.jobseeker .sub_3 .feature > :nth-child(2) > div {
    font-size: var(--font-size-xs);
  }

  .container_main.jobseeker .sub_3 .feature > :nth-child(3) {
    font-size: var(--font-size-sm);
  }

  .container_main.jobseeker .sub_3 .feature > :nth-child(4) > div {
    font-size: var(--font-size-xs);
  }

  .container_main.jobseeker .sub_3 > dl {
    padding: 0px 0px;
    flex-direction: column;
  }

  .container_main.jobseeker .sub_3 > dl > dt::after {
    display: none;
  }
  
  .container_main.jobseeker .sub_3 > dl > dt::before {
    display: none;
  }
}

.container_main.business > div > div > h2 {
  display: flex;
  align-items:flex-end;
  justify-content: center;
  color: var(--color-primary-dark) !important;
  line-height: 1.5;
  column-gap: 5px;
}

.container_main.business > div > div > h2 > img {
  width: 300px;
}

.container_main.business .sub_1 > p {
  text-align: center;
  font-size: var(--font-size-md);
}

.container_main.business .sub_1 > ul {
  text-align: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px; 
}

.container_main.business .sub_1 > ul > li > figure {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  height: 100%;
}

.container_main.business .sub_1 > ul > li > figure dl {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.container_main.business .sub_1 > ul > li > figure dt {
  text-align: center;
  color: var(--color-secondary);
  font-weight: bold;
  font-size: var(--font-size-lg);
}

.container_main.business .sub_1 > ul > li > figure dd {
  text-align: left;
  font-size: var(--font-size-md);
}

.container_main.business .sub_2 > p {
  padding: 0px 150px;
  font-size: var(--font-size-md);
}

.container_main.business .sub_2 > .feature {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr min-content;
  justify-items: center;
  column-gap: 10px;
  row-gap: 5px;
  padding: 0px 110px;
}

.container_main.business .sub_2 > .feature > :nth-child(1) {
  grid-row: 1 / 4;
  align-self: center;
}

.container_main.business .sub_2 > .feature > :nth-child(2) {
  justify-self: stretch;
  align-self: end;
}

.container_main.business .sub_2 > .feature > :nth-child(2) > div {
  display: block;
  text-align: center;
}

.container_main.business .sub_2 > .feature > :nth-child(2) > svg {
  display: block;
  stroke: var(--color-primary);
  fill: var(--color-primary);
  transform: scale(-1,1);
}

.container_main.business .sub_2 > .feature > :nth-child(3) {
  place-self: center;
  padding: 5px 20px;
  border: dotted var(--color-primary-dark) 2px;
  font-size: 24px;
  color: var(--color-primary-dark);
  font-weight: bold;
  white-space: nowrap;
  background-color: #fff;
}

.container_main.business .sub_2 > .feature > :nth-child(4) {
  justify-self: stretch;
  align-self: start;
}

.container_main.business .sub_2 > .feature > :nth-child(4) > div {
  display: block;
  text-align: center;
}

.container_main.business .sub_2 > .feature > :nth-child(4) > svg {
  display: block;
  stroke: var(--color-secondary-dark);
  fill: var(--color-secondary-dark);
}

.container_main.business .sub_2 > .feature > :nth-child(5) {
  grid-row: 1 / 4;
  grid-column: 3 / 4;
  align-self: center;
}

.container_main.business .sub_2 > .feature > :nth-child(6), 
.container_main.business .sub_2 > .feature > :nth-child(8) {
  align-self: start;
  padding: 2px 5px;
  background-color: var(--color-primary-light);
  color: black;
  text-align: center;
}

.container_main.business .sub_3 > ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 40px;
  row-gap: 20px;
}

.container_main.business .sub_3 > ul > li > figure {
  display: flex;
  flex-direction: column;
  gap: 5px
}

.container_main.business .sub_3 > ul > li > figure > figcaption {
  font-size: var(--font-size-lg);
  font-weight: bold;
}

.container_main.business .sub_3 > ul > li > figure > div {
  display: grid;
  grid-template-columns: 3fr 5fr;
  padding: 40px;
  border: solid 3px var(--color-primary-dark);
}



.container_main.business .sub_4 > ul {
  text-align: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px; 
}

.container_main.business .sub_4 > ul > li > figure {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  height: 100%;
}

.container_main.business .sub_4 > ul > li > figure > figcaption {
  align-self: stretch;
}

.container_main.business .sub_4 > ul > li > figure dl {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.container_main.business .sub_4 > ul > li > figure dt {
  text-align: center;
  color: var(--color-secondary);
  font-weight: bold;
  font-size: var(--font-size-lg);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.container_main.business .sub_4 > ul > li > figure dt > span {
  text-align: center;
  color: #fff;
  background-color: var(--color-secondary);
  font-weight: bold;
  font-size: var(--font-size-lg);
  border-radius: 30px;
  line-height: 1;
  padding: 11px 0px;

}

.container_main.business .sub_4 > ul > li > figure dd {
  text-align: left;
  font-size: var(--font-size-md);
}

.container_main.business .sub_5 > dl {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: min-content 1fr;
  column-gap: 40px;
  row-gap: 10px;
}

.container_main.business .sub_5 > dl > dt {
  grid-row: 1 / 2;
  font-size: var(--font-size-lg);
  color: var(--color-primary-dark);
  font-weight: bold;
}

.container_main.business .sub_5 > dl > dd {
  grid-row: 2 / 3;
  display: flex;
  border: solid 3px var(--color-primary-dark);
  border-radius: 10px;
  padding: 10px 20px;
  background-color: white;
  font-size: var(--font-size-lg);
  align-items: center;
}

.container_main.business .sub_5 > a {
  align-self: center;
  text-decoration: underline;
  font-size: var(--font-size-sm);
}

.container_main.business .sub_6 > details {
  background-color: var(--color-primary-lightest);
}

.container_main.business .sub_6 > details > summary {
  background-color: var(--color-primary-lightest);
  font-size: var(--font-size-lg);
  padding: 5px 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  line-height: 1;
  list-style: none;
  cursor: pointer;
}

.container_main.business .sub_6 > details > summary::before {
  content: "Q.";
  font-size: var(--font-size-2xl);
  font-weight: 900;
  font-family: 'Bai Jamjuree', sans-serif;
}

.container_main.business .sub_6 > details > div {
  background-color: var(--color-primary-lightest);
  font-size: var(--font-size-md);
  padding: 10px 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  list-style: none;
  border-top: solid 1px lightgray;
}

.container_main.business .sub_6 > details > div > p {
  font-size: var(--font-size-md);
}

.container_main.business .sub_6 > details > div::before {
  content: "A.";
  font-size: var(--font-size-2xl);
  font-weight: 900;
  font-family: 'Bai Jamjuree', sans-serif;
}

@media screen and (max-width: 650px) {
  .container_main.business > div > div > h2 {
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;
    line-height: 1;
    row-gap: 5px;
  }
  
  .container_main.business .sub_1 > ul {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    gap: 20px; 
  }
  
  .container_main.business .sub_1 > ul > li > figure {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    height: 100%;
  }
  
  .container_main.business .sub_1 > ul > li > figure dl {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .container_main.business .sub_1 > ul > li > figure dt {
    text-align: left;
    color: var(--color-secondary);
    font-weight: bold;
    font-size: var(--font-size-lg);
  }
  
  .container_main.business .sub_1 > ul > li > figure dd {
    text-align: left;
    font-size: var(--font-size-md);
  }

  .container_main.business .sub_2 > p {
    padding: 0px 0px;
  }
  .container_main.business .sub_2 > .feature {
    padding: 0px 0px;
  }

  .container_main.business .sub_2 > .feature > :nth-child(2) > div {
    font-size: var(--font-size-xs);
  }

  .container_main.business .sub_2 > .feature > :nth-child(3) {
    font-size: var(--font-size-sm);
  }

  .container_main.business .sub_2 > .feature > :nth-child(4) > div {
    font-size: var(--font-size-xs);
  }
  

  .container_main.business .sub_3 > ul {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
    column-gap: 10px;
    row-gap: 10px;
  }

  .container_main.business .sub_3 > ul > li > figure > div {
    padding: 20px 40px;
  }

  .container_main.business .sub_4 > ul {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px; 
  }

  .container_main.business .sub_5 > dl {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, min-content 1fr);
    column-gap: 40px;
    row-gap: 10px;
    align-self: center;
  }

  .container_main.business .sub_5 > dl > dt {
    grid-row: unset;
  }

  .container_main.business .sub_5 > dl > dd {
    grid-row: unset;
  }
}

.container_main.provider_manage > div > div > h2 {
  display: flex;
  justify-content: flex-start;
  color: #000 !important;
  column-gap: 5px;
  font-size: var(--font-size-xl);
}


.container_main.provider_manage .sub_1 > p {
  font-size: var(--font-size-md);
}

.container_main.provider_manage .sub_2 > a {
  text-decoration: underline;
}

.container_main.provider_manage .sub_2 > a > p {
  font-size: var(--font-size-md);
}


.container_main.provider_manage .sub_2 > fieldset {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: none;
}

.container_main.provider_manage .sub_2 > fieldset > legend {
  font-size: var(--font-size-md);
  font-weight: bold;
}

.container_main.provider_manage .sub_2 > fieldset > div {
  display: flex;
  gap: 20px;
  align-items: center;
}

.container_main.provider_manage .sub_2 > fieldset > div > span {
  background-color: var(--color-primary-dark);
  font-size: var(--font-size-md);
  font-weight: bold;
  color: #fff;
  padding: 5px 10px;
  border-radius: 10px;
  position: relative;
  align-self: flex-start;
}

.container_main.provider_manage .sub_2 > fieldset > div > span:after {
  background-color: var(--color-primary-dark);
  /* fill: var(--color-primary-lightest); */
  position: absolute;
  content: '';
  -webkit-mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAxMCI+PHBvbHlnb24gcG9pbnRzPSIxMCAwLCAyMCAwLCAwIDEwIiAvPjwvc3ZnPg=="), no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAxMCI+PHBvbHlnb24gcG9pbnRzPSIxMCAwLCAyMCAwLCAwIDEwIiAvPjwvc3ZnPg=="), no-repeat 50% 50%;
  mask-size: cover;
  width: 20px;
  height: 10px;
  top: 100%;
  right: 0px;
  transform: scaleX(-1);
}

.container_main.provider_manage .sub_2 > fieldset > div > label {
  width: 200px;
  font-size: var(--font-size-md);
}

.container_main.provider_manage .sub_2 > fieldset > div > div {
  flex: 1;
}

.container_main.provider_manage .sub_2 > fieldset > div:nth-child(3) {
  margin-bottom: 20px;
} 

.container_main.provider_manage .sub_2 > fieldset:nth-child(2) > div:nth-child(3) {
  margin-top: 20px;
} 

.container_main.provider_manage .skip_button {
  align-self: stretch;
  background-color: var(--color-secondary-dark);
  border-radius: 5px;
  padding: 10px;
  text-decoration: unset !important;
}

.container_main.provider_manage .skip_button:disabled {
  background-color: gray;
}

.container_main.provider_manage .skip_button > p {
  color: white;
  font-size: var(--font-size-md);
  text-align: center;
  display: none;
  font-weight: bold;
}

.container_main.provider_manage .skip_button.type_1 > p:nth-child(1) {
  display: block;
}

.container_main.provider_manage .skip_button.type_2 > p:nth-child(2) {
  display: block;
}

.container_main.provider_manage .sns_button {
  color: white;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  gap:5px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
}

.container_main.provider_manage .sns_button > img {
  height: 50px;
}
.container_main.provider_manage .sns_button.linkedin {
  background-color: var(--color-linkedin);
}
.container_main.provider_manage .sns_button.facebook {
  background-color: var(--color-facebook);
}

.container_main.provider_manage .profile_url_container {
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: 1fr min-content;
  position: relative;
}

.container_main.provider_manage .profile_url_container > input:focus {
  outline: none;
}

.container_main.provider_manage .profile_url_status {
  grid-column: 1 / 3;
  overflow: hidden;
  background-color: #d5eaff;
}

.container_main.provider_manage .profile_url_status > div {
  background-color: var(--color-primary);
  height: 3px;
  -webkit-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
}

.container_main.provider_manage .profile_url_status.success > div {
  background-color: green;
}

.container_main.provider_manage .profile_url_status.error > div {
  background-color: red;
}

.container_main.provider_manage .profile_url_status.progress > div {
  background-color: var(--color-primary);
  width: 100%;
  height: 3px;
  animation: indeterminateAnimation 1.5s infinite linear;
  transform-origin: 0% 50%;
}

.container_main.provider_manage .profile_url_message {
  position: absolute;
  top: calc(100%);
}

.container_main.provider_manage .profile_url_message > * {
  display: none;
}

.container_main.provider_manage .profile_url_message.success > .success {
  display: block;
}

.container_main.provider_manage .profile_url_message.error_1 > .error_1 {
  display: block;
}

.container_main.provider_manage .profile_url_message.error_2 > .error_2 {
  display: block;
}

.container_main.provider_manage .profile_url_message.progress > .progress {
  display: block;
}

.container_main.provider_manage .profile_url_message > .success {
  color: green;
}

.container_main.provider_manage .profile_url_message > [class^='error'] {
  color: red;
}

.container_main.provider_manage .profile_url_message > .progress {
  color: var(--color-primary);
}

.container_main.provider_manage .profile_url_alert {
  position: absolute;
  bottom: calc(100% + 5px);
  color: red;
  font-size: var(--font-size-md);
  padding: 2px 5px;
  left: 100px;
}

.container_main.provider_manage .profile_url_alert > a {
  color: red;
}

.container_main.provider_manage .profile_url_alert::before {
  height: 30px;
  border-left: solid 2px red;
  position: absolute;
  right: 100%;
  bottom: 0px;
  content: "";
  transform: rotate(-45deg);
  transform-origin: bottom center;
}

.container_main.provider_manage .profile_url_alert::after {
  height: 30px;
  border-right: solid 2px red;
  position: absolute;
  left: 100%;
  bottom: 0px;
  content: "";
  transform: rotate(45deg);
  transform-origin: bottom center;
}


.container_main.provider_manage .sub_3 > ul {
  display: grid;
  grid-template-columns: repeat(3, 250px);
  grid-template-rows: min-content;
  background-color: var(--color-primary-lightest);
  gap: 20px;
  height: 400px;
  justify-content: center;
  align-content: center;
} 

.container_main.provider_manage .sub_3 > ul figure {
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-radius: 20px;
  background-color: #85dfd9;
  gap: 20px;
  width: 100%;
  height: 100%;
}
.container_main.provider_manage .sub_3 > ul figure > img {
  padding: 0px 30px;
}

.container_main.provider_manage .sub_3 > ul figure > figcaption {
  text-align: center;
  padding: 0px 20px;
  color: var(--color-primary-dark);
  font-size: var(--font-size-lg);
  font-weight: bold;
}

.container_main.provider_manage .sub_3 > ul > li:nth-child(1) > figure {
  animation: jump1 2.5s 0s infinite normal;
}

.container_main.provider_manage .sub_3 > ul > li:nth-child(2) > figure {
  animation: jump2 2.5s 0s infinite normal;
}

.container_main.provider_manage .sub_3 > ul > li:nth-child(3) > figure {
  animation: jump3 2.5s 0s infinite normal;
}

.container_main.provider_manage .sub_3 > div {
  background-color: var(--color-primary-lightest);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  padding: 10px;
} 

.container_main.provider_manage .sub_3 > div > img {
  box-shadow: 0px 0px 0px 2px black;
  max-width: 100%;
  max-height: 100%;
} 

@media screen and (max-width: 650px) {
  .container_main.provider_manage .sub_2 > fieldset {

  }
  
  .container_main.provider_manage .sub_2 > fieldset > legend {
    font-size: var(--font-size-md);
    font-weight: bold;
  }
  
  .container_main.provider_manage .sub_2 > fieldset > div {
    display: flex;
    gap: 20px;
    align-items: stretch;
    flex-direction: column;
  }
  
  .container_main.provider_manage .sub_2 > fieldset > div > span {
    align-self: stretch;
  }
  
  .container_main.provider_manage .sub_2 > fieldset > div > span:after {
    background-color: var(--color-primary-dark);
    /* fill: var(--color-primary-lightest); */
    position: absolute;
    content: '';
    -webkit-mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAxMCI+PHBvbHlnb24gcG9pbnRzPSIwIDAsIDIwIDAsIDEwIDEwIiAvPjwvc3ZnPg=="), no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAxMCI+PHBvbHlnb24gcG9pbnRzPSIwIDAsIDIwIDAsIDEwIDEwIiAvPjwvc3ZnPg=="), no-repeat 50% 50%;
    mask-size: cover;
    width: 20px;
    height: 10px;
    top: 100%;
    right: 50%;
    margin-right: -10px;
  }
  
  .container_main.provider_manage .sub_2 > fieldset > div > label {
    width: 200px;
    font-size: var(--font-size-md);
  }
  
  .container_main.provider_manage .sub_2 > fieldset > div > div {
    flex: 1;
  } 

  .container_main.provider_manage .sub_2 > fieldset:nth-child(2) > div {
    margin-top: 0px;
  }

  .container_main.provider_manage .sub_2 > fieldset:nth-child(2) > div > div {
    margin-top: 20px;
  } 

  .container_main.provider_manage .profile_url_alert {
    font-size: 3vw;
    left: 0px;
    margin: 0px 25px;
  }
  
  .container_main.provider_manage .profile_url_alert::before {
    height: 30px;
    border-left: solid 2px red;
    position: absolute;
    right: 100%;
    bottom: 0px;
    content: "";
    transform: rotate(-45deg);
    transform-origin: bottom center;
  }
  
  .container_main.provider_manage .profile_url_alert::after {
    height: 30px;
    border-right: solid 2px red;
    position: absolute;
    left: 100%;
    bottom: 0px;
    content: "";
    transform: rotate(45deg);
    transform-origin: bottom center;
  }

  .container_main.provider_manage .sub_3 > ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 100px);
    background-color: var(--color-primary-lightest);
    gap: 20px;
    height: 400px;
    justify-content: center;
    align-content: center;
    padding: 20px 40px;
  } 
  
  .container_main.provider_manage .sub_3 > ul figure {
    display: flex;
    flex-direction: row;
    padding: 20px;
    border-radius: 20px;
    background-color: #85dfd9;
    gap: 20px;
  }
  .container_main.provider_manage .sub_3 > ul figure > img {
    padding: 0px 0px;
  }
  
  .container_main.provider_manage .sub_3 > ul figure > figcaption {
    text-align: center;
    padding: 0px 0px;
    color: var(--color-primary-dark);
    font-size: var(--font-size-lg);
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .container_main.provider_manage .sub_3 > ul > li:nth-child(1) > figure {
    animation: slide1 2.5s 0s infinite normal;
  }
  
  .container_main.provider_manage .sub_3 > ul > li:nth-child(2) > figure {
    animation: slide2 2.5s 0s infinite normal;
  }
  
  .container_main.provider_manage .sub_3 > ul > li:nth-child(3) > figure {
    animation: slide3 2.5s 0s infinite normal;
  }
}


@keyframes jump1 {
  0% { transform: translateY(0px); }
  0% { transform: translateY(0px); animation-timing-function: cubic-bezier(0.1,0.7,0.3,0.9); }
  20% { transform: translateY(-30px); animation-timing-function: cubic-bezier(0.7,0.1,0.9,0.3); }
  40% { transform: translateY(0px); animation-timing-function: cubic-bezier(0.1,0.7,0.3,0.9); }
  45% { transform: translateY(-5px); animation-timing-function: cubic-bezier(0.7,0.1,0.9,0.3); }
  50% { transform: translateY(0px); }
  100% { transform: translateY(0px); }
}

@keyframes jump2 {
  0% { transform: translateY(0px); }
  10% { transform: translateY(0px); animation-timing-function: cubic-bezier(0.1,0.7,0.3,0.9); }
  30% { transform: translateY(-30px); animation-timing-function: cubic-bezier(0.7,0.1,0.9,0.3); }
  50% { transform: translateY(0px); }
  55% { transform: translateY(-5px); animation-timing-function: cubic-bezier(0.7,0.1,0.9,0.3); }
  60% { transform: translateY(0px); }
  100% { transform: translateY(0px); }
}

@keyframes jump3 { 0% { transform: translateY(0px); }
  0% { transform: translateY(0px); }
  20% { transform: translateY(0px); animation-timing-function: cubic-bezier(0.1,0.7,0.3,0.9); }
  40% { transform: translateY(-30px); animation-timing-function: cubic-bezier(0.7,0.1,0.9,0.3); }
  60% { transform: translateY(0px); }
  65% { transform: translateY(-5px); animation-timing-function: cubic-bezier(0.7,0.1,0.9,0.3); }
  70% { transform: translateY(0px); }
  100% { transform: translateY(0px); }
}

@keyframes slide1 {
  0% { transform: translateX(0px); }
  0% { transform: translateX(0px); animation-timing-function: cubic-bezier(0.1,0.7,0.3,0.9); }
  20% { transform: translateX(15px); animation-timing-function: cubic-bezier(0.7,0.1,0.9,0.3); }
  40% { transform: translateX(0px); }
  100% { transform: translateX(0px); }
}

@keyframes slide2 {
  0% { transform: translateX(0px); }
  10% { transform: translateX(0px); animation-timing-function: cubic-bezier(0.1,0.7,0.3,0.9); }
  30% { transform: translateX(15px); animation-timing-function: cubic-bezier(0.7,0.1,0.9,0.3); }
  50% { transform: translateX(0px); }
  100% { transform: translateX(0px); }
}

@keyframes slide3 { 0% { transform: translateX(0px); }
  0% { transform: translateX(0px); }
  20% { transform: translateX(0px); animation-timing-function: cubic-bezier(0.1,0.7,0.3,0.9); }
  40% { transform: translateX(15px); animation-timing-function: cubic-bezier(0.7,0.1,0.9,0.3); }
  60% { transform: translateX(0px); }
  100% { transform: translateX(0px); }
}

.resume-cont {
    display: block !important;
    max-width: 1000px;
    margin: auto;
    width: 100%;
}

.resume-cont p {
    font-size: var(--font-size-md);
}

.resume-cont h1 {
    text-align: start;
    font-size: 32px;
    padding-left: 10px;
    border-left: 5px solid var(--color-linkedin);
}

.resume-cont h3 {
    color: #fff;
    padding-bottom: 5px;
    margin: 20px auto 10px;
    font-size: 150%;
    padding: 15px;
    border-radius: 10px;
    background-color: var(--color-linkedin);
}

.resume-cont h4 {
    font-size: 18px;
    margin: 20px auto 10px;
    padding-left: 10px;
    border-left: 3px solid var(--color-linkedin);
}

.resume-cont span {
    font-size: var(--font-size-lg);
    font-weight: bold;
    line-height: 40px;
    color: var(--color-linkedin);
}

.resume-cont img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    margin: 20px auto;
}

.list-table {
    margin-top: 30px;
}

.list-table td {
    border: 1px solid var(--color-linkedin);
    font-size: var(--font-size-md);
    padding: 10px;
}

.list-table td:first-child {
    color: var(--font-size-md);
    background-color: var(--color-primary-lightest);
}